import {
  Section,
  Enter,
  Grid,
  Spacing,
  Wrap,
  Lead,
  Title,
  Button,
  Skin,
} from '@etchteam/mobius';
import NextImage from 'next/image';
import Link from 'next/link';

import Stack from 'components/composition/Stack/Stack';
import PageTitle from 'components/content/PageTitle/PageTitle';
import hero from 'public/images/hero/home.webp';

const Home = () => {
  return (
    <>
      <PageTitle />
      <Section>
        <Stack>
          <Stack.Child>
            <NextImage src={hero} alt="" layout="fill" objectFit="cover" />
          </Stack.Child>
          <Stack.Child>
            <Wrap size="xxxl" gutter="none">
              <Grid wrap justify="flex-end">
                <Grid.Item xs={12} md={8} xl={6}>
                  <Skin skin="dark">
                    <Section>
                      <Spacing
                        top="fluid-lg"
                        bottom="fluid-lg"
                        left="fluid"
                        right="fluid"
                      >
                        <Spacing top="fluid" bottom="fluid">
                          <Spacing bottom="lg">
                            <Title size="h1">
                              Waste & Recycling Information for Local
                              Authorities
                            </Title>
                            <Lead>
                              <p>
                                WRAP’s LA Portal provides access to data on
                                local authority recycling and waste schemes in
                                the UK, including performance benchmarks for
                                kerbside dry recycling and residual collections.
                              </p>
                            </Lead>
                          </Spacing>
                          <Enter type="fade-in-up">
                            <Grid wrap spacing="lg">
                              <Grid.Item xs={12} lg={6}>
                                <p>
                                  <strong>Returning user?</strong>
                                </p>
                                <Button
                                  type="primary"
                                  block
                                  as={Link}
                                  href="/auth/login"
                                >
                                  Log in
                                </Button>
                              </Grid.Item>
                              <Grid.Item xs={12} lg={6}>
                                <p>Don&apos;t have an account?</p>
                                <Button block as={Link} href="/auth/register">
                                  Register for free
                                </Button>
                              </Grid.Item>
                            </Grid>
                          </Enter>
                        </Spacing>
                      </Spacing>
                    </Section>
                  </Skin>
                </Grid.Item>
              </Grid>
            </Wrap>
          </Stack.Child>
        </Stack>
      </Section>
    </>
  );
};

export default Home;
