import { ReactNode } from 'react';

import Child from './Child';
import styles from './Stack.module.scss';

export default function Stack({ children }: { readonly children?: ReactNode }) {
  return <div className={styles.stack}>{children}</div>;
}

Stack.Child = Child;
